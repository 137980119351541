<template>
  <div class="warning-report-table-container">
    <div class="table">
      <a-table
        class="m_table_cust"
        size="middle"
        :pagination="false"
        :rowKey="(record) => record.id"
        :data-source="dataList"
        :loading="isLoading"
        :expanded-row-keys.sync="expandedRowKeys"
        :childrenColumnName="null"
        :expandIconColumnIndex="-1"
        :expandIconAsCell="false"
        bordered
      >
        <!-- 数据时间 -->
        <a-table-column align="left" dataIndex="date" :width="100">
          <span slot="title">数据时间</span>
        </a-table-column>
        <!-- 预警账户 -->
        <a-table-column v-if="role === 'SUPER_ADMIN'" align="left" dataIndex="company" :width="200">
          <span slot="title">预警账户</span>
        </a-table-column>
        <!-- 预警名称 -->
        <a-table-column align="left" dataIndex="name" :width="100">
          <span slot="title">预警名称</span>
        </a-table-column>
        <!-- 预警状态 -->
        <a-table-column align="left" dataIndex="sts" :width="60">
          <span slot="title">预警状态</span>
          <template slot-scope="sts">
            <a-tag :color="sts === 'A' ? 'green' : 'red'">
              {{ sts === 'A' ? '开启' : '关闭' }}
            </a-tag>
          </template>
        </a-table-column>
        <!-- 预警对象 -->
        <a-table-column align="left" dataIndex="target" :width="60">
          <span slot="title">预警对象</span>
          <template slot-scope="target">
            {{ target === '1' ? '应用' : '广告位' }}
          </template>
        </a-table-column>
        <!-- 预警频率 -->
        <a-table-column align="left" dataIndex="frequency" :width="60">
          <span slot="title">预警频率</span>
          <template slot-scope="frequency">
            {{ frequency === 2 ? '小时级' : '天级' }}
          </template>
        </a-table-column>
        <!-- 预警通知时间 -->
        <a-table-column align="left" dataIndex="noticeDate" :width="100">
          <span slot="title">预警通知时间</span>
        </a-table-column>
        <!-- 操作 -->
        <a-table-column align="left" :width="100">
          <span slot="title">操作</span>
          <template slot-scope="text, record">
            <!-- 查看详情，展开子table -->
            <a-button @click="handleExpandItem(record)" type="link">查看详情</a-button>
          </template>
        </a-table-column>
        <div class="expandedRowRender" slot="expandedRowRender" slot-scope="record">
          <a-table size="middle" :pagination="false" :rowKey="(r) => r.id" :data-source="record.children">
            <!-- 应用 -->
            <a-table-column align="left" dataIndex="appName" :width="150">
              <span slot="title">应用</span>

              <template slot-scope="appName, r">
                <div>{{ appName }}</div>
                <div>ID：{{ r.appId }}</div>
              </template>
            </a-table-column>
            <!-- 广告位 -->
            <a-table-column v-if="record.target === '2'" align="left" dataIndex="placeName" :width="150">
              <span slot="title">广告位</span>
              <template slot-scope="placeName, r">
                <div>{{ placeName }}</div>
                <div>ID：{{ r.placeId }}</div>
              </template>
            </a-table-column>
            <!-- 预警实际触发值 -->
            <a-table-column align="left" :width="500">
              <span slot="title">预警实际触发值</span>
              <template slot-scope="r">
                <div>
                  <span style="color: red">{{ dealRuleId(r.ruleId) }}</span>
                  {{
                    `${record.frequency === 1 ? '昨天' : '今天'}【${record.newDate}】比${
                      record.frequency === 1 ? '前一天' : '昨天'
                    }【${record.oldDate}】${record.frequency === 2 ? `在${record.hour}时段` : ''}`
                  }}
                  <span style="color: red">{{ r.type == 1 ? '上浮' : '下跌' }}</span>

                  {{ `${r.floatValue}%；` }}
                </div>
                <div>
                  {{
                    `${record.frequency == 1 ? '昨天' : '今天'}【${record.newDate}】：${r.newValue}${dealSuffix(
                      r.ruleId
                    )}；${record.frequency == 1 ? '前一天' : '昨天'}【${record.oldDate}】：${r.oldValue}${dealSuffix(
                      r.ruleId
                    )}`
                  }}
                </div>
              </template>
            </a-table-column>
            <!-- 操作 -->
            <a-table-column align="left" :width="60">
              <span slot="title">操作</span>
              <template slot-scope="placeName, r">
                <!--  -->
                <a-button style="color: #52c41a" @click="handleDetails(r, record)" type="link">查看更多数据</a-button>
              </template>
            </a-table-column>
          </a-table>
        </div>
      </a-table>
      <a-pagination
        class="pagination"
        v-if="dataList.length > 0"
        show-size-changer
        :current="searchquery.page"
        :show-total="(total) => `共 ${total} 条`"
        :total="total"
        @change="handleChangePage"
        @showSizeChange="onShowSizeChange"
        :pageSize="searchquery.limit"
      />
    </div>
    <m-empty v-if="dataList.length === 0" style="height: 80vh">
      <template #description>暂无数据 </template>
    </m-empty>
  </div>
</template>

<script>
import { getWarningReportPage } from '@/api/reportdatas'
import { mapState } from 'vuex'
export default {
  data () {
    return {
      expandedRowKeys: [],
      dataList: [],
      isLoading: false,
      searchquery: {
        page: 1,
        limit: 10
      },
      total: 0
    }
  },
  computed: {
    ...mapState({
      role: (state) => state.user.roles[0],
      title: (state) => state.autoweb.title,
      audit: (state) => state.user.audit
    })
  },
  methods: {
    // 供父级调用
    dealquery (query) {
      this.searchquery.page = 1
      this.searchquery = { ...this.searchquery, ...query }
      this.getTableData()
    },
    // 获取table数据
    async getTableData () {
      this.isLoading = true
      const resp = await getWarningReportPage(this.searchquery)
      this.dataList = resp.data ? resp.data.items : []
      this.total = resp.data ? resp.data.total : 0
      this.isLoading = false
    },
    // 查看详情，展开子table
    handleExpandItem (record) {
      if (this.expandedRowKeys[0] && this.expandedRowKeys[0] === record.id) {
        this.expandedRowKeys = []
      } else {
        this.expandedRowKeys = [record.id]
      }
    },
    // 获取预警指标字段名
    dealRuleId (id) {
      switch (id) {
        case 1:
          return 'DAU'
        case 2:
          return 'ARPDAU'
        case 3:
          return '流量请求'
        case 4:
          return '流量填充率'
        case 5:
          return '展示'
        case 6:
          return '预估收益'
        case 7:
          return '展示率'
        case 8:
          return '预估eCPM'
      }
    },
    // 数据后缀
    dealSuffix (id) {
      switch (id) {
        case 1:
          return ''
        case 2:
          return ''
        case 3:
          return ''
        case 4:
          return '%'
        case 5:
          return ''
        case 6:
          return ''
        case 7:
          return '%'
        case 8:
          return ''
      }
    },
    // 跳转
    handleDetails (r, record) {
      if (record.frequency === 1) {
        this.$router.push({
          name: 'synthreport',
          params: { query: this.searchquery, r, record }
        })
      } else if (record.frequency === 2) {
        this.$router.push({
          name: 'hoursreport',
          params: { query: this.searchquery, r, record }
        })
      }
    },
    // 修改page
    handleChangePage (page) {
      this.searchquery.page = page
      this.getTableData()
    },
    // 修改limit
    onShowSizeChange (current, size) {
      this.searchquery.page = 1
      this.searchquery.limit = size
      this.getTableData()
    }
  }
}
</script>

<style lang="less">
.warning-report-table-container {
  margin-top: 10px;
  min-height: 80vh;
  .table {
    .ant-table-thead tr th span {
      color: #000;
      font-weight: 600;
    }
    // .ant-table-tbody tr td {
    //   font-size: 12px !important;
    // }
  }
  .pagination {
    margin: 10px 0;
  }
}
</style>
