<template>
  <div class="warning-report-container">
    <ReportSearch @handleAutoSearch="handleAutoSearch" />
    <ReportTable ref="table" />
  </div>
</template>

<script>
import ReportSearch from './ReportSearch'
import ReportTable from './ReportTable'
export default {
  name: 'WarningReport',
  components: { ReportSearch, ReportTable },
  methods: {
    handleAutoSearch (query) {
      this.$nextTick(() => {
        this.$refs.table.dealquery(query)
      })
    }
  }
}
</script>

<style lang="less">
.warning-report-container {
  height: 100%;
}
</style>
