<template>
  <div class="warning-report-search-container">
    <span class="shaixuan">筛选</span>
    <a-range-picker
      class="search_item"
      format="YYYY-MM-DD"
      :placeholder="['Start', 'End']"
      @change="ChangeDate"
      :value="rangePickerDate"
      :allowClear="false"
      :ranges="{
        今日: [initDate(0, 0).start, initDate(0, 0).end],
        昨日: [initDate(1, 1).start, initDate(1, 1).end],
        最近一周: [initDate(6, 0).start, initDate(6, 0).end],
        最近一个月: [initDate(30, 0).start, initDate(30, 0).end]
      }"
      :disabledDate="disabledDate"
    >
      <!-- <a-icon slot="suffixIcon" type="calendar" /> -->
    </a-range-picker>
    <m-select-one
      class="search_item"
      :popoverWidth="'240px'"
      v-if="role === 'SUPER_ADMIN'"
      label="账户"
      :showId="false"
      :hasOs="false"
      :hasPosition="false"
      :allData="adminList"
      v-model="searchquery.createUser"
      :allowClear="true"
      @change="changeCreateUser"
    />
    <m-select-one
      class="search_item"
      :popoverWidth="'240px'"
      label="预警"
      :showId="false"
      :hasOs="false"
      :hasPosition="false"
      :allData="warningList"
      v-model="searchquery.id"
      :allowClear="true"
      @change="handleAutoSearch"
    />
    <a-select
      allowClear
      v-model="searchquery.target"
      placeholder="请选择预警对象"
      class="search_item"
      @change="handleAutoSearch"
    >
      <a-select-option :value="1"> 应用 </a-select-option>
      <a-select-option :value="2"> 广告位 </a-select-option>
    </a-select>
    <a-select
      allowClear
      v-model="searchquery.sts"
      placeholder="请选择预警状态"
      class="search_item"
      @change="handleAutoSearch"
    >
      <a-select-option value="A"> 开启 </a-select-option>
      <a-select-option value="S"> 关闭 </a-select-option>
    </a-select>
  </div>
</template>

<script>
import mixDate from '@/mixins/initDate'
import mixGetList from '@/mixins/getList'
import { getWarningByUser } from '@/api/reportdatas'
import { mapState } from 'vuex'
import { getDuration } from '@/utils/duration'
export default {
  mixins: [mixDate, mixGetList],
  data () {
    return {
      searchquery: {
        createUser: undefined,
        endDate: '',
        id: undefined,
        startDate: '',
        sts: undefined,
        target: undefined
      },
      warningList: []
    }
  },
  created () {
    // 初始化时间
    const { endDate, startDate, end, start } = this.initDate(6, 0)
    this.rangePickerDate = [start, end]
    this.searchquery.endDate = endDate
    this.searchquery.startDate = startDate
    this.$emit('handleAutoSearch', this.searchquery)
    this.getAdminList()
    this.getWarningByUser()
  },
  computed: {
    ...mapState({
      role: (state) => state.user.roles[0],
      title: (state) => state.autoweb.title
    })
  },
  methods: {
    ChangeDate (date, dateString) {
      this.searchquery = {
        ...this.searchquery,
        endDate: dateString[1],
        startDate: dateString[0]
      }
      this.rangePickerDate = date
      this.handleAutoSearch()
    },
    // 不可选时间
    disabledDate (current) {
      // 默认大于今天的时间 小于2021.9
      return (current && current < this.initDate(getDuration(), 0).start) || current > this.initDate(0, 0).end
    },
    // 修改账户回调
    async changeCreateUser () {
      await this.getWarningByUser()
      this.handleAutoSearch()
    },
    // 通过账号查预警
    async getWarningByUser () {
      const resp = await getWarningByUser({ createUser: this.searchquery.createUser })
      this.warningList = resp.data || []
    },
    handleAutoSearch () {
      this.$emit('handleAutoSearch', this.searchquery)
    }
  }
}
</script>

<style lang="less">
.warning-report-search-container {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
  .shaixuan {
    width: 70px;
    color: #333;
    font-weight: 500;
  }
  .search_item {
    width: 210px;
    margin: 10px 10px 10px 0;
  }
}
</style>
